define("ember-cli-new-version/components/new-version-notifier/component", ["exports", "ember-cli-new-version/components/new-version-notifier/template", "ember-concurrency", "fetch"], function (_exports, _template, _emberConcurrency, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let taskRunCounter = 0;
  const MAX_COUNT_IN_TESTING = 10;
  const ONE_MINUTE = 60000;

  var _default = Ember.Component.extend({
    layout: _template.default,
    tagName: '',
    enableInTests: false,
    updateInterval: ONE_MINUTE,
    firstCheckInterval: 0,
    versionFileName: "/VERSION.txt",
    updateMessage: "This application has been updated from version {oldVersion} to {newVersion}. Please save any work, then refresh browser to see changes.",
    showReload: true,
    reloadButtonText: "Reload",

    onNewVersion() {},

    onError(e) {
      if (!Ember.testing) {
        console.log(e);
      }
    },

    // internal state:
    lastVersion: null,
    version: null,
    _fastboot: Ember.computed(function () {
      let owner = Ember.getOwner(this);
      return owner.lookup('service:fastboot');
    }),
    url: Ember.computed('versionFileName', function () {
      const config = Ember.getOwner(this).resolveRegistration('config:environment');
      const versionFileName = Ember.get(config, 'newVersion.fileName') || this.get('versionFileName');
      const baseUrl = Ember.get(config, 'newVersion.prepend') || config.rootURL || config.baseURL;

      if (!config || baseUrl === '/') {
        return versionFileName;
      }

      return baseUrl + versionFileName;
    }).readOnly(),

    init() {
      this._super(...arguments);

      if (this.get('_fastboot.isFastBoot')) {
        return;
      }

      if (Ember.testing) {
        taskRunCounter = 0;
      }

      if (!Ember.testing || Ember.get(this, 'enableInTests')) {
        if (this.firstCheckInterval > 0) {
          Ember.run.later(this, () => {
            this.get('updateVersion').perform();
          }, this.firstCheckInterval);
        } else {
          this.get('updateVersion').perform();
        }
      }
    },

    updateIntervalWithTesting: Ember.computed('updateInterval', 'enableInTests', function () {
      let enableInTests = Ember.get(this, 'enableInTests');
      return !enableInTests && Ember.testing ? 0 : Ember.get(this, 'updateInterval');
    }),
    updateVersion: (0, _emberConcurrency.task)(function* () {
      const url = this.get('url');

      try {
        yield (0, _fetch.default)(url + '?_=' + Date.now()).then(response => {
          if (!response.ok) throw new Error(response.statusText);
          return response.text();
        }).then(res => {
          const currentVersion = this.get('version');
          const newVersion = res && res.trim();

          if (this.updateNeeded(currentVersion, newVersion)) {
            const message = this.get('updateMessage').replace('{oldVersion}', currentVersion).replace('{newVersion}', newVersion);
            this.setProperties({
              message,
              lastVersion: currentVersion
            });
            this.onNewVersion(newVersion, currentVersion);
          }

          this.set('version', newVersion);
        });
      } catch (e) {
        this.onError(e);
      } finally {
        let updateInterval = this.get('updateIntervalWithTesting');

        if (updateInterval === null || updateInterval === undefined) {
          updateInterval = ONE_MINUTE;
        }

        yield (0, _emberConcurrency.timeout)(updateInterval);

        if (Ember.testing && ++taskRunCounter > MAX_COUNT_IN_TESTING) {
          return;
        }

        if (Ember.testing && !Ember.get(this, 'enableInTests')) {
          return;
        }

        this.get('updateVersion').perform();
      }
    }),

    updateNeeded(currentVersion, newVersion) {
      return currentVersion && newVersion !== currentVersion;
    },

    actions: {
      reload() {
        if (typeof window !== 'undefined' && window.location) {
          window.location.reload(true);
        }
      },

      close() {
        this.set('message', undefined);
      }

    }
  });

  _exports.default = _default;
});